import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { Seo } from "../Layout/Seo";
import { Header } from "../SendMoneyPage/Header";
import { HowItWorks } from "./HowItWorks";
import { AvailableInAnyMoment } from "./AvailableInAnyMoment";
import { FiveReasons } from "./FiveReasons";
import { WhySendity } from "./WhySendity";
import { Landings } from "./Landings";

const SendMoneyCountries = ({ pageContext, location, lang }) => {
  const data = useStaticQuery(graphql`
    query StaticAllStrapiHomeQuery {
      allStrapiHome {
        nodes {
          locale
          block7 {
            title
            country {
              title
              url
              flag {
                url
                alternativeText
                ext
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP], quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiHome.nodes.filter(({ locale }) => locale === lang)[0];

  const { page: fragments } = pageContext;

  return (
    <Seo title={fragments.seoTitle} description={fragments.seoDescription}>
      {/* <div className="cn_page_category" style={{ display: "none" }}>
        remesas
      </div> */}
      <Header
        data={{ title: fragments.Title }}
        country_code={pageContext.country_code}
        location={location}
        lang={lang}
        flagsPricingSendities={data.block7}
      />
      <HowItWorks data={fragments.HowItWorks[0]} />
      <AvailableInAnyMoment data={fragments.AvailableInAnyMoment} />
      <FiveReasons data={fragments.fiveReasons} />
      <WhySendity data={fragments.WhySendity} />
      <Landings data={data.block7} lang={lang} />
    </Seo>
  );
};
export default SendMoneyCountries;
